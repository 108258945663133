const select_departamento = document.getElementById('reclamo_departamento_id');
const select_provincia    = document.getElementById('reclamo_province_id');
const select_distrito     = document.getElementById('reclamo_district_id');

select_departamento.addEventListener("change", setProvincia);
select_provincia.addEventListener("change", setDistrito);

function setProvincia() {
	let myRequest = new Request("/departamentos/" + select_departamento.value + "/provinces.json");
	fetch(myRequest)
	.then((response) => response.json())
	.then((data) => {
			select_provincia.innerHTML = "";
			data.forEach(province => {
				let opt = document.createElement('option');
				opt.value = province.id;
				opt.innerHTML = province.desc;
    		select_provincia.appendChild(opt);
			});
			setDistrito();
	})
	.catch(console.error);
}

function setDistrito() {
	let myRequest = new Request("/departamentos/" + select_departamento.value + "/provinces/" + select_provincia.value + "/districts.json");
	fetch(myRequest)
	.then((response) => response.json())
	.then((data) => {
			select_distrito.innerHTML = "";
			data.forEach(district => {
				let opt = document.createElement('option');
				opt.value = district.id;
				opt.innerHTML = district.desc;
    		select_distrito.appendChild(opt);
			});
	})
	.catch(console.error);
}
